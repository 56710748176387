<template>
  <div>
    <router-view></router-view>
    <div v-if="$route.path == '/user/myLoan'">
      <el-dialog title="授信流程提示" :visible.sync="dialogVisible" width="975px">
        <div class="OrderDetail_top">

          <div v-if="inquiryFail.code==4" class="steps df aic jfc">
            <div v-for="(item, index) in Fsteps" :key="index" class="tou df aic">
              <div class="tep" style="text-align: center">
                <div style="margin-bottom: 18px" :style="item.steps <= inquiryFail.code ?'background-color:#26C487':'background-color:#E9ECF0'" class="status">
                  <img style="icon20" :src=" item.status.F" alt />

                  <div class="time">
                    <div class="itep">{{item.tep}}</div>
                    <div style="
                    color: #707070;
                    line-height: 1.5;
                    font-size: 12px;
                  " class="day">
                      {{ item.Status }}
                    </div>
                  </div>
                </div>

              </div>
              <div :style="
                index + 1 >= inquiryFail.code + 1
                  ? 'background-image:linear-gradient(to right, #CDCDCD 0%, #CDCDCD 50%, transparent 50%);'
                  : ''
              " class="xuxian"></div>
            </div>
          </div>
          <div v-else class="steps df aic jfc">
            <div v-for="(item, index) in steps" :key="index" class="tou df aic">
              <div class="tep" style="text-align: center">
                <div style="margin-bottom: 18px" :style="item.steps <=  inquiryFail.code ?'background-color:#26C487':'background-color:#E9ECF0'" class="status">
                  <img style="icon20" :src="item.status.T" alt />

                  <div class="time">
                    <div class="itep">{{item.tep}}</div>
                    <div style="
                    color: #707070;
                    line-height: 1.5;
                    font-size: 12px;
                  " class="day">
                      {{ item.Status }}
                    </div>
                  </div>
                </div>

              </div>
              <div :style="
                index + 1 >= inquiryFail.code + 1
                  ? 'background-image:linear-gradient(to right, #CDCDCD 0%, #CDCDCD 50%, transparent 50%);'
                  : ''
              " class="xuxian"></div>
            </div>
          </div>
          <div class="bolang"></div>
        </div>
      </el-dialog>
      <!-- 授信主体-->
      <div>
        <header>
          <div class="tt df">
            <div class="suxian"></div>
            我的药采贷
          </div>
          <el-select v-model="currentBank" placeholder="请选择银行" @change="changeBank">
            <el-option label="宜宾商业银行" :value="100" />
            <el-option label="四川天府银行" :value="200" />
            <el-option label="中国邮政储蓄银行" :value="300" />
          </el-select>
          <span v-show="currentBank===300" class="postal-qrcode" @click="postalVisible = true">支付</span>
          <div class="box">
            <div class="list">
              <img src="@/assets/images/Loan/green.png" alt="" />
              <div class="right">
                <div class="price">{{ from.currentCreditLine }}</div>
                <div class="txt">当前总授信额度(元)</div>
              </div>
            </div>
            <div class="solid"></div>
            <div class="list">
              <img src="@/assets/images/Loan/blue.png" alt="" />
              <div class="right">
                <div class="price">{{ from.surplusCreditLine }}</div>
                <div class="txt">剩余授信额度(元)</div>
              </div>
            </div>
            <div class="solid"></div>
            <div class="list">
              <img src="@/assets/images/Loan/orange.png" alt="" />
              <div class="right">
                <div class="price">{{ from.presentMonthInterest }}</div>
                <div class="txt">本月待还利息(元)</div>
              </div>
            </div>
            <div class="solid"></div>
            <div class="list">
              <img src="@/assets/images/Loan/skyblue.png" alt="" />
              <div class="right">
                <div class="price">{{ from.hasInterest }}</div>
                <div class="txt">累计已还利息(元)</div>
              </div>
            </div>
          </div>
          <el-button type="success" class="apply-btn" @click="applyLoan">申请药采贷</el-button>
        </header>
        <div class="parting"></div>
        <div style="padding-top: 32px" class="MyMessage p32">
          <div class="tt df">
            <div class="suxian"></div>
            贷款基本信息
          </div>
          <div class="navlist">
            <div @click="swichNav('')" :class="active == '' ? 'active li' : 'li'">
              全部贷款
            </div>
            <div @click="swichNav(1)" :class="active == 1 ? 'active li' : 'li'">
              待还款
            </div>
            <div @click="swichNav(2)" :class="active == 2 ? 'active li' : 'li'">
              已还款
            </div>
          </div>
          <div class="serchtab">
            <div class="li">
              <div class="serchName">贷款号</div>
              <div class="ipt">
                <el-input placeholder="请输入内容" clearable v-model="loanId">
                </el-input>
              </div>
            </div>
            <div class="li">
              <div class="serchName">支付单号</div>
              <div class="ipt">
                <el-input placeholder="输入支付单号" clearable v-model="orderNo">
                </el-input>
              </div>
            </div>
            <div class="li">
              <div class="ss">
                <el-button @click="serchList" type="success">搜索</el-button>
                <el-button @click="reset">重置</el-button>
              </div>
            </div>
          </div>
          <div class="tabel">
            <el-table :header-cell-style="{ background: '#F8F8F8' }" ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" :cell-style="cellStyle">
              <el-table-column align="center" type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column align="center" property="addTime" label="申请时间" width="180" sortable>
              </el-table-column>
              <el-table-column align="center" property="loanId" label="贷款号" width="180">
              </el-table-column>

              <el-table-column align="center" property="orderNo" label="支付单" width="180">
              </el-table-column>
              <el-table-column align="center" property="custName" label="贷款人姓名" width="100">
              </el-table-column>
              <el-table-column align="center" property="appLoanAmt" label="贷款金额 (元)" width="auto">
              </el-table-column>
              <el-table-column align="center" property="state" label="贷款状态" width="auto">
                <template slot-scope="scope">
                  <div class="df jfc aic tac">
                    <div style="margin-right: 5px" class="del" :style="
                      scope.row.state != 1
                        ? '  background-color:#26C487'
                        : '  background-color:#FFA936'
                    "></div>
                    {{ scope.row.state != 1 ? "已还款" : "还款中" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" property="name" label="操作" width="180">
                <template slot-scope="scope">
                  <span @click="goOrderDetail(scope.row)" style=" color: #26C487; font-size: 14px;margin-right: 10px;" class="cup">
                    关联订单列表
                  </span>
                  <span v-if="currentBank==100" @click="goDetail(scope.row)" style="color: #26c487; font-size: 14px" class="cup">
                    贷款详情
                  </span>

                  <span v-if="currentBank==200" @click="payTianfu(scope.row)" style=" color: #26C487; font-size: 14px" class="cup">
                    还款
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagenation">
            <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10" layout="prev, pager, next, total,jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <div v-show="tianfuApplyVisible && currentBank == 200" class="empty-box">
        <div v-show="!loanMsg">
          <div class="code" ref="loanQrCode"></div>
          <div>请扫描二维码补全资料或点击下方链接</div>
          <a :href="loanUrl" target="_blank">点击此处跳转至天府银行页面处理授信相关信息</a>
        </div>
        <div v-show="loanMsg">
          <div style="margin-top: 250px">{{loanMsg}}</div>
        </div>
      </div>
      <div v-show="tianfuApplyVisible && currentBank == 300" class="empty-box">
        <div>
          <div class="no-bg-code" ref="fixQrCode"></div>
          <div>请扫描二维码或点击下方链接申请贷款</div>
          <a :href="loanUrl" target="_blank">点击此处跳转至邮储银行申请贷款</a>
        </div>
        <div class="notice-box">
          <div style="margin-bottom: 10px;">说明</div>
          <div>通过邮储银行链接完成贷款申请成功后，系统会将对应的贷款金额发放至您的慧金豆账户，您可使用慧金豆正常进行购买商品。</div>
        </div>
      </div>
      
    </div>

    <!-- 二维码弹框 -->
    <div class="paylog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="buy_code"
        width="430px"
        @close="closeQrcode"
      >
        <img src="@/assets/images/quicklyOrder/payIMG.png" alt="" />
        <div
          style="display: flex;justify-content: center;position: absolute;top: 65px;right: 0;transform: translate(-70px, 165px);"
          class="buy_box dis_f_c_c"
        >
          <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
          <img src="static/image/store/pay2.png" class="img2" alt="" />
        </div>
        <div @click="closeQrcode" style="margin-top: 40px" class="cup flex-center">
          <img src="@/assets/images/quicklyOrder/Close.png" alt="" />
        </div>
      </el-dialog>
    </div>

    <!-- 邮政银行二维码弹窗-->
    <el-dialog title="二维码" :visible.sync="postalVisible" width="360px" class="postal-box">
      <div>
        <div class="no-bg-code" ref="fixQrCode"></div>
        <div>请扫描二维码或点击下方链接申请贷款</div>
        <a :href="loanUrl" target="_blank">点击此处跳转至邮储银行申请贷款</a>
      </div>
      <div class="notice-box">
        <div style="margin-bottom: 10px;">说明</div>
        <div>通过邮储银行链接完成贷款申请成功后，系统会将对应的贷款金额发放至您的慧金豆账户，您可使用慧金豆正常进行购买商品。</div>
      </div>
    </el-dialog>

    <LoanApply :dialog-visible="applyVisible" @close="closeLoan"/>
  </div>

</template>
 
<script>
import LoanApply from "@/components/loanApply.vue";
import {
  collectloadList,
  particulars,
  getInquiryStatus,
  creditListAPI,
  getCreditUrlAPI,
  creditStatisticsAPI
} from "@/request/Loan/Loan";
import QRCode from "qrcodejs2"; // 引入qrcode,转二维码
import axios from "axios";
export default {
  components: {
    LoanApply
  },
  data() {
    return {
      Fsteps: [
        {
          steps: 1,
          Status: "待进件",
          tep: "第一步",
          status: {
            F: require("@/assets/images/Loan/s1.png"),
          },
        },
        {
          steps: 2,
          Status: "资料已录入",
          tep: "第二步",
          status: {
            F: require("@/assets/images/Loan/s2.png"),
          },
        },
        {
          steps: 3,
          Status: "预审",
          tep: "第三步",
          status: {
            F: require("@/assets/images/Loan/s3.png"),
          },
        },
        {
          steps: 4,
          Status: "预审失败",
          tep: "第四步",
          status: {
            F: require("@/assets/images/Loan/s4f.png"),
          },
        },
      ],
      steps: [
        {
          steps: 1,
          Status: "待进件",
          tep: "第一步",
          status: {
            T: require("@/assets/images/Loan/s1.png"),
          },
        },
        {
          steps: 2,
          Status: "资料已录入",
          tep: "第二步",
          status: {
            T: require("@/assets/images/Loan/s2.png"),
          },
        },
        {
          steps: 3,
          Status: "预审",
          tep: "第三步",
          status: {
            T: require("@/assets/images/Loan/s3.png"),
          },
        },
        {
          steps: 5,
          Status: "预审成功",
          tep: "第四步",
          status: {
            T: require("@/assets/images/Loan/s4t.png"),
          },
        },
        {
          steps: 6,
          Status: "银行建立白名单",
          tep: "第五步",
          status: {
            T: require("@/assets/images/Loan/s5.png"),
          },
        },
      ],
      dialogVisible: false,
      orderNo: "",
      loanId: "",
      page: 1,
      active: "",
      sort: 1, // 降序 1， 升序2
      total: 0,
      input: "",
      tableData: [],
      from: {},
      inquiryFail: {
        code: 0,
      },
      applyVisible: false,
      currentBank: 100,  // 当前授信主体
      buy_code: false,
      qrcode: "", // 二维码
      loanUrl:'',
      location:[],
      loanMsg:'',
      tianfuApplyVisible: false,
      postalVisible: false  // 邮储银行二维码
    };
  },
  created() {
    getInquiryStatus().then((res) => {
      if (res.code === 200) {
        this.inquiryFail = res.data;
        if (res.data.code == 6) {
          this.dialogVisible = false;
        } else {
          this.dialogVisible = true;
        }
      }
    });

    this.getCreditStatistics();
    this.getList();
    this.getLocation();
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    goOrderDetail(item) {
      this.$router.push({
        name: "MyLoanOrderList",
        params: item,
      });
    },
    goDetail(item) {
      this.$router.push({
        name: "MyLoanDetail",
        params: item,
      });
    },
    handleCurrentChange(val) {
      let that = this;
      this.page = val;
      that.getList();
    },
    reset() {
      this.loanId = "";
      this.orderNo = "";
      this.active = "";
    },
    swichNav(id) {
      if( id === 1) {
        this.sort = 2
      }else {
        this.sort = 1
      }
      this.active = id;
      this.getList();
    },
    serchList() {
      (this.page = 1), this.getList();
    },
    getList() {
      if(this.currentBank == 100) {
        let data = {
          loanId: this.loanId,
          orderNo: this.orderNo,
          pageCount: this.page,
          state: this.active,
          pageSize: 10,
          sort: this.sort
        };
        collectloadList(data).then((res) => {
          if (res.code === 200 && res.data) {
            this.tableData = res.data.content;
            this.total = res.data.totalElements;
            this.tianfuApplyVisible = false
          }
        });
      }
      if(this.currentBank == 200) {
        let data = {
          loanId: this.loanId,
          bankType: this.currentBank,
          paySn: this.orderNo,
          pageNum: this.page,
          state: this.active,
          pageSize: 10,
        }
        creditListAPI(data).then(res => {
          if(res.code === 200) {
            this.tableData = res.data.rows;
            this.total = res.data.total;
            if (this.tableData.length === 0 && !this.active) {
              this.applyTianfu();
              this.tianfuApplyVisible = true
            } else {
              this.tianfuApplyVisible = false
            }
          }
        })
      }
    },

    // 获取统计信息
    getCreditStatistics() {
      let API;
      let bankType;
      if(this.currentBank == 100) {
        API = particulars;
      }
      if(this.currentBank == 200) {
        API = creditStatisticsAPI;
        bankType = this.currentBank;
      }
      API(bankType).then((res) => {
        if (res.code === 200) {
          this.from = res.data;
        }
      });
    },

    // 药采贷申请
    applyLoan(){
      this.applyVisible = true
    },
    closeLoan(value) {
      this.applyVisible = value
      if (this.currentBank == 300) {
        this.tianfuApplyVisible = true;
        this.loanUrl = "https://wap.psbc.com/mobilebank/yunxindaiMenuInfo.do?version=html5&printNo_temp=o1FgEjzxhZJBgk1NPhGayU8zNxaM";
        this.$nextTick(() => {
          this.qrcode = new QRCode(this.$refs.fixQrCode, {
            width: 184, //二维码宽度
            height: 180, //二维码高度
            text: this.loanUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
            colorDark: "#000", //颜色配置
            colorLight: "#fff",
          });
        });
      }
    },

    // 切换授信主题
    changeBank() {
      if (this.$refs.loanQrCode) {
        this.$refs.loanQrCode.innerHTML = "";
      }
      if (this.$refs.fixQrCode) {
        this.$refs.fixQrCode.innerHTML = "";
      }

      if (this.currentBank == 300) {
        this.tianfuApplyVisible = true;
        this.loanUrl = "https://wap.psbc.com/mobilebank/yunxindaiMenuInfo.do?version=html5&printNo_temp=o1FgEjzxhZJBgk1NPhGayU8zNxaM";
        this.$nextTick(() => {
          this.qrcode = new QRCode(this.$refs.fixQrCode, {
            width: 184, //二维码宽度
            height: 180, //二维码高度
            text: this.loanUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
            colorDark: "#000", //颜色配置
            colorLight: "#fff",
          });
        });
      } else {
        this.tianfuApplyVisible = false;
        this.getList();
        this.getCreditStatistics();
      }
    },
    // 天府银行还款
    payTianfu(item) {
      let data = {
        customerAddrId: this.$store.state.AddrId,
        gpsX: '30.53006918',
        gpsY: '103.9017713',
        id: [item.id]
      }
      getCreditUrlAPI(data).then(res => {
        if(res.code === 200) {
          this.buy_code = true;
          this.$nextTick(() => {
            this.qrcode = new QRCode(this.$refs.qrcode, {
              width: 300, //二维码宽度
              height: 300, //二维码高度
              text: res.data.url, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
              colorDark: "#000", //颜色配置
              colorLight: "#fff",
            });
          });
        }
      })
    },
    // 天府银行授信跳转
    applyTianfu() {
      let data = {
        customerAddrId: this.$store.state.AddrId,
        gpsX: this.location[0],
        gpsY: this.location[1],
      }
      getCreditUrlAPI(data).then(res => {
        if(res.code === 200) {
          if (res.data.url) {
            this.loanUrl = res.data.url;
            this.$nextTick(() => {
              this.qrcode = new QRCode(this.$refs.loanQrCode, {
                width: 152, //二维码宽度
                height: 152, //二维码高度
                text: this.loanUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
                colorDark: "#000", //颜色配置
                colorLight: "#fff",
              });
            });
          }else {
            this.loanMsg = res.data.msg
          }
        }
      })
    },
    getLocation() {
      axios({
        url: `https://restapi.amap.com/v3/ip?key=d369aeea0b01d0c9a5c4b0fac64127ad`,
      })
        .then((res) => {
          if (res.status == 200) {
            const loc = res.data.rectangle.split(";")[0].split(",");
            this.location[0] = loc && loc[1] ? loc[1] : "30.53006918"
            this.location[1] = loc && loc[0] ? loc[0] : "103.9017713"
          }
        })
        .catch((error) => {});
    },
    
    //  关闭二维码弹窗
    closeQrcode(){
      this.buy_code = false
      this.$refs.qrcode.innerHTML = "";
    },
  },
};
</script>
<style lang = "less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-dialog__body {
    color: #707070;
    font-size: 14px;
    word-break: break-all;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 35px;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}

.time {
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translate(-50%);
  white-space: nowrap;
  .itep {
    font-size: 14px;
    font-family: 'regular';
    font-weight: 400;
    color: #707070;
    margin-bottom: 10px;
  }
  .day {
    font-size: 16px;
    font-family: 'regular';
    font-weight: bold;
    color: #333;
  }
  /* position: absolute; */
}
.status {
  position: relative;
  width: 64px;
  height: 64px;
  /* background-color: @green; */
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.xuxian {
  width: 100px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    @green 0%,
    @green 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.xuxianF {
  width: 100px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    #cdcdcd 0%,
    #cdcdcd 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.tou:last-child {
  .xuxian {
    display: none;
  }
}
.OrderDetail_top {
  position: relative;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  background-color: #fff;
}
header {
  padding: 30px;
  background: #ffffff;
  position: relative;
  .box {
    display: flex;
    justify-content: space-between;
    padding: 40px 0px 0px;
  }
  .list {
    display: flex;
    .right {
      margin-left: 25px;
      .price {
        margin: 5px 0 10px 0;
        font-size: 26px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #2e323c;
      }
      .txt {
        font-size: 14px;
        font-family: 'regular';
        font-weight: 400;
        color: #60666f;
      }
    }
  }
  .solid {
    border-right: 1px solid #f5f5f5;
  }
  .list:nth-child(3) {
    margin-right: 0;
    border-right: 0;
  }
  .apply-btn {
    position: absolute;
    top: 24px;
    right: 30px;
  }
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.serchtab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .li {
    height: 36px;
    margin-bottom: 30px;
    line-height: 36px;
    width: 30%;
    display: flex;
    justify-content: space-between;
    .serchName {
      margin-right: 20px;
      font-size: 14px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
  line-height: 0px;
}
.navlist {
  margin-top: 20px;
  display: flex;
  .li {
    cursor: pointer;
    text-align: center;
    padding: 0 20px;
    font-size: 16px;
    padding-bottom: 15px;
    font-weight: 400;
    color: #333333;
  }
  .active {
    color: #26c487;
    border-bottom: 4px solid #26c487;
  }
}

.paylog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

.empty-box {
  text-align: center;
  color: #000000;
  font-family: 'medium';
  .code {
    width: 330px;
    height: 230px;
    background-image: url(../../../assets/images/Loan/box-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: -10px;
    margin: 150px auto 33px;
    padding: 56px 0 0 90px;
  }
  .no-bg-code {
    width: 184px;
    height: 180px;
    margin: 120px auto 33px;
  }
  a {
    display: inline-block;
    color: #0057FE;
    margin-top: 14px;
    text-decoration: underline;
  }
  .notice-box {
    width: 459px;
    height: 86px;
    background: #F1FFFA;
    border-radius: 10px;
    border: 1px solid #26C487;
    margin: 60px auto 0;
    padding: 8px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #26C487;
  }
}

.parting {
  height: 20px;
  background: #F3F5F7;
}

.postal-qrcode {
  display: inline-block;
  width: 72px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #26C487;
  font-size: 12px;
  color: #26C487;
  text-align: center;
  line-height: 32px;
  margin-left: 8px;
  cursor: pointer;
}
.postal-box {
  :deep(.el-dialog__header) {
    display: none;
  }
  :deep(.el-dialog__body) {
    background-image: url(../../../assets/images/Loan/qrcode-bg.png);
    background-size: auto;
    background-repeat: no-repeat;
    background-color: #F3F5F7;
  }
}
</style>
